<script setup lang="ts">
import { ref, watch } from "vue";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import ArrowIcon from "~/assets/svg/icons/arrow.svg";

interface IVCollapsePanelProps {
  title?: string;
  expanded?: boolean;
}

const props = withDefaults(defineProps<IVCollapsePanelProps>(), {
  expanded: false,
  title: "",
});

const emit = defineEmits<{
  "update:expanded": [data: boolean];
}>();

const innerExpanded = ref(props.expanded);

watch(
  () => props.expanded,
  (next) => {
    innerExpanded.value = next;
  },
);

const toggle = () => {
  innerExpanded.value = !innerExpanded.value;
  emit("update:expanded", innerExpanded.value);
};

const start = (el: Element) => {
  if (el instanceof HTMLElement) {
    el.style.height = el.scrollHeight + "px";
  }
};

const end = (el: Element) => {
  if (el instanceof HTMLElement) {
    el.style.height = "";
  }
};
</script>

<template>
  <div class="collapse-panel" :class="{ 'collapse-panel-expanded': innerExpanded }">
    <header class="collapse-panel__header" @click="toggle">
      <slot name="title">
        <VText font="body-large-accent" class="collapse-panel__header-title">
          {{ title }}
        </VText>
      </slot>
      <VIcon :rotate="innerExpanded ? '-90deg' : '90deg'" class="collapse-panel__header-arrow">
        <ArrowIcon />
      </VIcon>
    </header>
    <transition
      name="transition-collapse"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div v-if="innerExpanded" class="collapse-panel__content-wrap">
        <div class="collapse-panel__content">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="postcss">
.collapse-panel {
  background-color: var(--pl-surface-muted-default);
  color: var(--pl-text-primary);
  border-radius: var(--pl-unit-x3);
  height: max-content;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: var(--pl-unit-x3);
    border-radius: var(--pl-unit-x3);
    cursor: pointer;
    gap: var(--pl-unit-x3);

    &-arrow {
      transition: transform 0.3s;
    }

    &-title {
      padding: var(--pl-unit-x1) 0;
    }

    .collapse-panel-expanded & {
      border-color: var(--pl-surface-muted-default);
    }
  }

  &__content {
    padding: 0 var(--pl-unit-x3) var(--pl-unit-x3);
  }
}

.transition-collapse-enter-active,
.transition-collapse-leave-active {
  will-change: height, opacity;
  transition:
    height 0.3s ease,
    opacity 0.3s ease;
  overflow: hidden;
}

.transition-collapse-enter-from,
.transition-collapse-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
